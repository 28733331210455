import axios from '@/common/js/request'
export function updateResolutionState(data){   // 工单解决状态修改-管明杨
    const updateResolutionState = axios({
        url: '/data/updateResolutionState',
        method: 'post',
        data
    })
    return updateResolutionState
}
export function anomalyDataList(params){   // PC工单管理(非居民异常工单列表)-管明杨
    const anomalyDataList = axios({
        url: '/data/anomalyDataList',
        method: 'get',
        params
    })
    return anomalyDataList
}