<template>
    <div>
        <div class="content">
            <div class="all">
                <div class="table">
                    <!--搜索框-->
                    <el-form
                        ref="search"
                        :inline="true"
                        :model="search"
                        size="small"
                        label-width="80px"
                    >
                        <el-form-item label="用户分类">
                            <!--  @change="loadList(1)" -->
                        <el-select v-model="search.userType" clearable multiple collapse-tags placeholder="请选择" filterable>
                            <el-option
                            v-for="item in userTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                        <el-form-item label="任务编号">
                        <el-select v-model="search.taskCode" clearable multiple collapse-tags placeholder="请选择" filterable>
                            <el-option
                            v-for="item in serial"
                            :key="item"
                            :label="item"
                            :value="item"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                        <el-form-item label="片区">
                        <el-select v-model="search.addrId" clearable multiple collapse-tags placeholder="请选择" filterable>
                            <el-option
                            v-for="item in taskAddrdata"
                            :key="item.addrId"
                            :label="item.addrName"
                            :value="item.addrId"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                        <el-form-item label="安检员">
                        <el-select v-model="search.userId" clearable multiple collapse-tags placeholder="请选择" filterable>
                            <el-option
                            v-for="item in taskInspectorData"
                            :key="item.userId"
                            :label="item.inspectorName"
                            :value="item.userId"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="工单状态">
                            <el-select v-model="search.statusFlag" clearable placeholder="请选择" @change="loadList(1)">
                                <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="解决状态">
                            <el-select v-model="search.resolutionState" clearable placeholder="请选择" filterable>
                                <el-option
                                v-for="item in solveStatus"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="采集模板">
                        <el-select v-model="search.tplId" clearable placeholder="请选择" filterable>
                            <el-option
                            v-for="item in taskTplIdData"
                            :key="item.tplId"
                            :label="item.tplName"
                            :value="item.tplId"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                        <el-form-item label="检查时间">
                        <el-date-picker
                            style="width: 230px"
                            v-model="times"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                           >
                        </el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                        <el-input placeholder="请输入关键字" v-model="search.keyWordValue">
                            <el-select placeholder="请选择" v-model="search.keyWord" slot="prepend">
                                <el-option v-for="item in searchBar" :key="item.id" :label="item.label" :value="item.id"></el-option>
                            </el-select>
                        </el-input>
                        </el-form-item>
                        
                        <el-form-item label-width="20px">
                        <div>
                            <el-button type="primary" size="small" @click="loadList"
                            >查询</el-button
                            >
                            <el-button type="warning"  size="small" @click="reset"
                            >重置</el-button
                            >
                        </div>
                        </el-form-item>
                    </el-form>
                    <div style="display: flex; margin-bottom: 10px">
						<el-button
							type="text"
							style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"
							plain
                            v-if="$anthButtons.getButtonAuth('ycglyjj')"
							@click="solve(2)"
							size="small"
							>已解决</el-button
						>
					</div>
                    <!--数据表格-->
                    <el-table
                        ref="tableData"
                        :data="tableData"
                        style="width: 100%;"
                        border
                        height="calc(100vh - 380px)"
                        stripe
                        :header-cell-style="{
                        color: '#333',
                        fontFamily: 'MicrosoftYaHeiUI',
                        fontSize: '14px',
                        fontWeight: 900,
                        textAlign:'left',
                        background: '#f8f8f9',
                        }"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            :selectable="selectableFn"
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column
                            type="index"
                            label="序号"
                            width="70"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="dataCode"
                            label="工单编号"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="taskCode"
                            label="任务编号"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            label="用户名称"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="contact"
                            label="联系人"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="userPhone"
                            label="电话"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="userType"
                            label="用户分类"
                            show-overflow-tooltip
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="addrName"
                            label="片区"
                            width="120"
                        >
                            <template slot-scope="{row}">
                                <el-tooltip class="item" effect="dark" :content="row.addrName" placement="top-start">
                                    <div class="twoLines">{{row.addrName}}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="地址"
                            width="120"
                        >
                            <template slot-scope="{row}">
                                <el-tooltip class="item" effect="dark" :content="row.address" placement="top-start">
                                <div class="twoLines">{{row.address}}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="deptName"
                            label="负责部门"
                            show-overflow-tooltip
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column prop="inspectorName" label="安检员"  width="120">
                            <template slot-scope="{row}">
                                <div class="twoLines">
                                    <p>
                                    {{fillterName(row.inspectorName)[0]}}
                                    <br/>
                                    ({{fillterName(row.inspectorName)[1]}}
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="safetyResultsName" label="安检结果"  width="80"></el-table-column>
                        <el-table-column prop="anomalyNumber" label="异常项数量"  width="90"></el-table-column>
                        <!-- <el-table-column prop="statusFlagName" label="工单状态"  width="80">
                            <template slot-scope="{row}">
                                <el-tag :type="row.statusFlagName == '已完成' ? 'success' : 'danger'">{{row.statusFlagName}}</el-tag>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="checkTime"
                            label="检查时间"
                            width="120"
                        ></el-table-column>
                        <el-table-column
                            prop="inspectionTime"
                            label="检查时长"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            label="解决状态"
                            width="120"
                        >
                            <template slot-scope="{row}">
                                {{row.resolutionState == 1 ? '未解决' : '已解决'}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="remark"
                            label="备注"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="operationBy"
                            label="操作人"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="operationTime"
                            label="操作时间"
                            width="160"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column label="操作" align="left" width="180" fixed="right">
                            <template slot-scope="{ row }">
                                <el-button type="primary" size="small" @click="addItem(3, row)"
                                        v-if="$anthButtons.getButtonAuth('ycglck')"
                                >查看</el-button>
                                <el-button type="success" size="small" @click="solve(1,row)"
                                        v-if="$anthButtons.getButtonAuth('ycglyjj') && row.resolutionState==1"
                                >已解决</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pageBox">
                        <el-pagination
                        :current-page="search.current"
                        :background="true"
                        :page-sizes="[30, 50, 100]"
                        :page-size="search.size"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                    <el-dialog
                        :visible.sync="dialogVisible"
                        width="30%"
                        @close="closeDialog"
                        :close-on-click-modal="false">
                        <el-form :model="form">
                            <el-form-item label="备注" label-width="50px">
                                <el-input v-model="form.remark" type="textarea" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="subFix">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import {abnormalCodeList,abnormalAddrList,abnormalInspectorList,abnormalTplIdList} from "@/RequestPort/workorder/maintenRecord";
import {userTypeDropDown} from "@/RequestPort/induction";
import {updateResolutionState, anomalyDataList} from "@/RequestPort/workorder/unusualOrder.js";
export default {
    name: 'unusualOrder',
    data () {
        return {
            search: {
                addrId: '',	// 片区id	query	false	
                endTime: '',	// 检查时间结束时间	query	false	
                keyWord: 5,	// 1用户名称 2联系人 3电话 4地址 5工单编号	query	false	
                keyWordValue: '',	// 关键字搜索值	query	false	
                resolutionState: '',	// 解决状态 1未解决 2已解决	query	false	
                // safetyResults: '',	// 安检结果 1正常入户 2到访不遇 3拒绝安检 4停业	query	false	
                startTime: '',	// 检查时间开始时间	query	false	
                // statusFlag	: '', //工单状态 1已完成 2未完成	query	false	
                taskCode: '',	// 任务编号	query	false	
                taskId: '',	// 任务id	query	false	
                tplId: '',	// 采集模板id	query	false	
                userId: '',	// 安检员id	query	false	
                userType: '',	// 用户分类id	query	false	
                size: 100,	// 当前页条数,默认值为10	query	false	
                current: 1,	// 当前页,默认值为1	query	false	
            },
            total: 0,
            // 列表数据
            tableData:[],
            // 用户分类
            userTypes: [],
            // 任务编号
            serial:[],
            // 片区下拉
            taskAddrdata:[],
            // 安检员下拉
            taskInspectorData:[],
            // 安检结果
            checkerList: [
            { label: "正常安检", id: 1 },
            { label: "到访不遇", id: 2 },
            { label: "拒绝安检", id: 3 },
            { label: "停用", id: 4  },
            ],
            // 工单状态
            statusList: [
                { label: "已完成", id: 1 },
                { label: "未完成", id: 2 },
            ],
            // 解决状态
            solveStatus: [
                { label: "已解决", id: 2 },
                { label: "未解决", id: 1 },
            ],
            // 采集模板下拉
            taskTplIdData:[],
            // 关键字条件
            searchBar:[
            { label: "用户名称", id: 1 },
            { label: "联系人", id: 2 },
            { label: "电话", id: 3 },
            { label: "地址", id: 4 },
            { label: "工单编号", id: 5 },
            ],
            // 时间
            times:[],
            // 解决弹窗显示
            dialogVisible: false,
            // 已解决填备注
            form: {remark: ''},
            // 表格已选中
            selected: []
        }
    },
    mounted() {
        // 获取列表数据
        this.loadList()
        // 任务编号下拉
        this.gettaskCodeList()
        // 用户分类
        this.getuserTypeDropDown()
        // 片区下拉
        this.gettaskAddrList()
        // 安检员下拉
        this.gettaskInspectorList()
        // 采集模板下拉
        this.gettaskTplIdList()
    },
    methods: {
        /**
         * 提交解决
         */
        subFix() {
            updateResolutionState({
                dataId: this.selected.toString(),
                remark: this.form.remark
            }).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.dialogVisible = false
                    this.loadList()
                }
            })
        },
        /**
         * 已解决
         */
        solve(type, row) {
            // type 1单个解决 2批量解决
            if (type==1) {
                this.selected = [row.dataId]
            } else if (type==2) {
                if (this.selected.length === 0) {
                    this.$message.error('请选择至少一条数据')
                    return
                }
            }
            this.dialogVisible = true
        },
        /**
         * 关闭解决对话框
         */
        closeDialog() {
            this.form.remark = ''
            this.selected = []
        },
        /**
         * 表格selection禁用
         */
        selectableFn(row) {
            if (row.resolutionState==1) {
                return true
            } else {
                return false
            }
        },
        /**
         * 表格多选
         */
        handleSelectionChange(val) {
            this.selected = val.map(item => {
                return item.dataId
            })
        },
        fillterName(name){
            return name.split('(')
        },
        /**
         * 采集模板下拉
         */
        gettaskTplIdList(){
            abnormalTplIdList().then(res => {
                this.taskTplIdData = res.data
            })
        },
        /**
         * 安检员下拉
         */
        gettaskInspectorList(){
            abnormalInspectorList().then(res => {
                this.taskInspectorData = res.data
            })
        },
        /**
         * 片区下拉
         */
        gettaskAddrList(){
            abnormalAddrList().then(res => {
                this.taskAddrdata = res.data
            })
        },
        /***
         * 用户分类
         */
        getuserTypeDropDown(){
            userTypeDropDown().then(res => {
                this.userTypes = res.data
            })
        },
        /**
         * 任务编号下拉
         */
        gettaskCodeList(){
            abnormalCodeList().then(res => {
                this.serial = res.data
            })
        },
        /**
         * 更换条数
         */
        handleSizeChange(val){
            this.search.current = 1
            this.search.size = val
            this.loadList()
        },
        /**
         * 更换页码
         */
        handleCurrentChange(val){
            this.search.current = val
            this.loadList()
        },
        /**
         * 获取列表数据
         */
        loadList(val){
            let searchObj = {}
            if(val){
                this.search.current = 1
            }
            if(this.times && this.times.length>0) {
                this.search.startTime = this.times[0]
                this.search.endTime = this.times[1]
            } else {
                this.search.startTime = ''
                this.search.endTime = ''
            }
            for(let key in this.search) {
                if (key != 'size' && key != 'current' && key != 'keyWord' &&
                    key != 'resolutionState' && key != 'tplId' && key != 'startTime'
                    && key != 'endTime') {
                    searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
                    // console.log(String(this.search[key]))
                } else {
                    searchObj[key] = this.search[key]
                }
            }
            anomalyDataList(searchObj).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        /**
         * 查看页面
         */
        addItem(index,row){
            // this.$router.push({
            //   path: "/home/workorder/details",
            //   query: { id: row.dataId },
            // });
            const routeData = this.$router.resolve({
                        path: '/home/workorder/details',      //跳转目标窗口的地址
                        query: { id: row.dataId }
                    })
                    window.open(routeData.href);
        },
        /**
         * 重置
         */
        reset() {
            this.search = {
                addrId: '',	// 片区id	query	false	
                endTime: '',	// 检查时间结束时间	query	false	
                keyWord: 5,	// 1用户名称 2联系人 3电话 4地址 5工单编号	query	false	
                keyWordValue: '',	// 关键字搜索值	query	false	
                resolutionState: '',	// 解决状态 1未解决 2已解决	query	false	
                // safetyResults: '',	// 安检结果 1正常入户 2到访不遇 3拒绝安检 4停业	query	false	
                startTime: '',	// 检查时间开始时间	query	false	
                // statusFlag	: '', //工单状态 1已完成 2未完成	query	false	
                taskCode: '',	// 任务编号	query	false	
                taskId: '',	// 任务id	query	false	
                tplId: '',	// 采集模板id	query	false	
                userId: '',	// 安检员id	query	false	
                userType: '',	// 用户分类id	query	false	
                size: 100,	// 当前页条数,默认值为10	query	false	
                current: 1,	// 当前页,默认值为1	query	false
            }
            this.times = []
            this.loadList()
        }
    }
}
</script>
 
<style scoped lang="less">
::v-deep .el-input-group__prepend{
  width: 67px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh - 150px);
}
</style>